.header-title {
    background-color: #000;
    padding: 5px;
    padding-left: 10px;
    color: #fff;
    font-weight: bold;
    width: 100%;
    text-align: left;
}

.project-header-title {
    background-color: #000;
    padding: 5px;
    padding-left: 10px;
    color: #fff;
    font-weight: bold;
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
}

.page-layout {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
}
.project-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 48px;
}

.accordion{
    padding-top: 16px;
}

.MuiCircularProgress-root{
  margin: 2rem;
}

.static-number-field-animated {

}

.static-number-field {
    
}

.static-number-field-animated {
    animation: .5s ease-in background-animation, .5s ease-out background-animation-reverse;
}

/* it is possible to animate multiple properties in a single animation */
@keyframes background-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgb(255, 242, 0);
  }
}
@keyframes background-animation {
    from {
      background-color: rgb(255, 242, 0);
    }
    to {
      background-color: transparent;
    }
  }