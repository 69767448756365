h1 {
    font-size: 1.3rem !important; 
    color: #fff;
    padding-right: 20px;
}

h3 {
    font-size: 1.3rem !important; 
    color: #fff;
    padding-right: 10px;
}